.App {
  text-align: center;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 20px;
  max-width: 600px;
  min-width: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-family: 'Raleway', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* //////// My app */
h1 {
  font-family: 'Raleway', sans-serif;
  padding: 10px;
}

h2{
  font-family: 'Raleway', sans-serif;
  padding: 10px;
}
h4{
  font-family: 'Raleway', sans-serif;
  padding-top: 30px;
}

h5{
  font-family: 'Raleway', sans-serif;
  padding-top: 30px;
}

h3{
  font-family: 'Raleway', sans-serif;
  margin-top: 30px;
}
h6{
  font-family: 'Raleway', sans-serif;
  padding-top: 10px;
  
}

.capa{
  margin-top: 5;
}

.about{
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch; 
}

.description{
  margin-top: 50px;
  text-align: justify;
  text-justify: inter-word;
  width: 80%;
  
}

.navLink{
  color: black;
  text-decoration: none;
}

.navLink:hover{
  color: rgb(214, 156, 200);
  opacity: 0.6;
  text-decoration: none;
}

.nav{
  min-width: fit-content(20em);
}

.Hamburger{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
  margin-right: 130px;
}

.discography{
  margin: auto;
  display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  align-content: center;
  max-width: 370px;
}

.albumsDiv{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
  align-content: center;
  margin-top: 10px;
}

.bandcamp{
  border: none;
  margin:5px;
}

.icons {
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: center;
  position:absolute;
  margin-top: -70px;
  margin-left: 135px;
  padding: 20px;
}

@media (max-width: 768px){

  .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    margin-top: -70px;
    margin-left: 35px;
    /* width: 100%; */
    /* padding-left: 0px; */
  }

    .Hamburger{
      /* float: left; */
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      /* align-items: stretch;
      align-content: stretch; */
      margin-right: 10px;
    }

}

.img-responsive{
margin: 5px;
padding: 2px;
}

.img-responsive:hover{
  opacity: 0.8;
}

.workDiv{
  margin: auto;
  display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
  width: 80%;
}

.mawnBandcamp{
  border: none;
  margin: 5px;
}

.paraisoDiv{
  text-align: left;
}

.paraisoPlayer{
  border: none;
  margin: 5px;
}
.mawnworkDiv{
  display: flex;
	flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.religare{
  display: flex;
	flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}


hr{
  width: 5px;
}


.videosDiv{
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 100%;
}
.videoWraper {
  margin-top: 30px;

}

.videoPlayer{
  border: none;
}

.videoWraper iframe {
  width: 100%;
  height: 100%;
}

.topPage{
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
  align-content: stretch;
  /* margin: auto; */
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}